<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-width-small">
              <span class="uk-text-bold">Jenis Sapronak</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Kode Barang</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Nama Barang</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Jumlah</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Satuan</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Harga Satuan</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Total Harga</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="retur.meta.total_count>0">
            <tr
              v-for="(data, i) in retur.data"
              :key="i"
            >
              <td>{{ data.ukirama_category_item || '-' }}</td>
              <td>{{ data.item_code || '-' }}</td>
              <td>{{ data.item_name || '-' }}</td>
              <td>{{ data.delivery_quantity || 0 }}</td>
              <td>{{ data.unit_code || '-' }}</td>
              <td>
                {{
                  data.ukirama_category_item == 'OVK' ? setCurrency(data.price_unit) : setCurrency(data.contract_price_unit) || '-'
                }}
                <!-- data.ukirama_category_item == 'OVK' ? setCurrency(data.unit_price_plus_unit_price_x_margin) : setCurrency(data.contract_price_unit) -->
              </td>
              <td>
                {{ data.ukirama_category_item == 'OVK' ? 
                  data.price_contract_partner == data.delivery_quantity * data.price_unit ? 
                    setCurrency(data.price_contract_partner) : setCurrency(data.delivery_quantity * data.price_unit) : setCurrency(data.price_contract_partner) || '-' }} 
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="7"
          />
        </template>
        <template v-else>
          <loading-table :colspan="7" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="retur.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Pagination from '@/components/globals/Pagination'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { dateUtcParanjeString, float0Decimals, currency } from '@/utils/formater'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination
  },
  data() {
    return {
      is_loading: true
    }
  },
  computed: {
    ...mapGetters({
      retur: 'sapronak/table_detail',
      meta: 'sapronak/metaTable'
    })
  },
  watch: {
    retur () {
      this.is_loading = false
    }
  },
  methods: {
    ...mapMutations({
      setMeta: 'sapronak/SET_META_TABLE'
    }),
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    setCurrency(date) {
      const newNum = float0Decimals(date)
      return currency(newNum)
    },
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    }
  }
}
</script>
