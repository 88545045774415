<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-text-right">
          <button
            v-if="!is_loading"
            class="uk-button uk-button-primary uk-margin-left"
            :disabled="is_loading"
            @click="handleSync"
          >
            <img
              v-lazy="`${images}/icon/sync.svg`"
              alt=""
              class="uk-margin-small-right img-click"
            >
            Sync Data
          </button>
          <button
            v-else
            style="cursor:unset; min-width:167.47px;"
            class="uk-button uk-button-primary uk-margin-left"
          >
            <div uk-spinner />
          </button>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Stock Transfer
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="retur.code"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Kode Siklus
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="retur.log_cycle_code"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama Mitra
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="retur.farm_name"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama Kandang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="retur.cage_name"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Siklus ke
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="retur.cycle_count"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Kota/Kabupaten
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="retur.city"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Tanggal Kirim
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="retur.delivery_at"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Tanggal Terima
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="retur.received_at"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Status
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <LabelStatus :status="retur.status" />
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Terakhir Update
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="retur.updated_at"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Table Detail Farm -->
      <TableRetur />
      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-top"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <router-link
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            to="/admin/sapronak"
          >
            <img
              v-lazy="`${images}/icon/back.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Kembali
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import TableRetur from './table'
import LabelStatus from '@/components/globals/LabelStatus'
import { dateParanjeCompleteString } from '@/utils/formater'
import { mapActions, mapGetters } from 'vuex'
import {
  notificationSuccess
} from '@/utils/notification'

export default {
  components: {
    TableRetur,
    LabelStatus
  },
  props: {
    retur: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE
    }
  },
  computed:{
    ...mapGetters({
      syncDataSapronak: 'sapronak/sync_data'
    })
  },
  watch: {
    retur() {
      this.retur.delivery_at = dateParanjeCompleteString(this.retur.delivery_at)
      this.retur.received_at = dateParanjeCompleteString(this.retur.received_at)
      this.retur.updated_at = dateParanjeCompleteString(this.retur.updated_at)
    }
  },
  methods: {
    ...mapActions({
      syncSapronak: 'sapronak/syncSapronak'
    }),
    async handleSync () {
      this.is_loading = true
      await this.syncSapronak({ code: String(this.retur.code), sync_type: 'all' })
      if (this.syncDataSapronak.message == 'OK') {
        notificationSuccess(`Sync ${this.retur.code} Berhasil`)
        setTimeout(function() { window.location.reload()}, 1000)
      }
      this.is_loading = false
    }
  }
  
}
</script>
