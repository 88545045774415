<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div
      class="uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1 font-header truncate-2-lines">
        Detail "{{ retur.code }}"
      </div>
    </div>

    <container :retur="retur" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'

export default {
  components: {
    Container
  },
  computed: {
    ...mapGetters({
      retur: 'sapronak/detail_retur',
      meta: 'sapronak/metaTable'
    })
  },
  watch: {
    async meta () {
      await this.getSapronakTableDetail({...this.meta, ukirama_stock_transfer_id: this.retur.id})
    }
  },
  async mounted() {
    await this.getReturDetail(this.$route.params.id)
    await this.getSapronakTableDetail({ ukirama_stock_transfer_id: this.retur.id })
  },
  methods: {
    ...mapActions({
      getReturDetail: 'sapronak/getReturDetail',
      getSapronakTableDetail: 'sapronak/getSapronakTableDetail'
    })
  }
}
</script>
